var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Applications")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Application","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","light":"","to":{ name: _vm.$route.name + '-archived' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}])},[_c('span',[_vm._v("View Archive Applications")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"grid-row"},_vm._l((_vm.applications_group),function(group){return _c('v-simple-table',{key:group.id,staticClass:"mb-5 table-bordered"},[_c('tbody',[_c('tr',{staticClass:"grey lighten-3"},[_c('td',[(group.applications.length == 1)?_c('div',[_vm._v("Individual")]):_c('div',[_vm._v("Group: "+_vm._s(group.group_name))])]),_c('td',{attrs:{"colspan":"8"}},[_vm._v(" Property: "+_vm._s(group.applications[0].property.property_name)+" "+_vm._s(group.applications[0].property.postcode)+" "),(group.applications.length > 1)?_c('span',{staticClass:"ml-2"},[(
                        (group.applications[0].property.rent * 30) / 12 <=
                        _vm.groupSumSolary(group)
                      )?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle ")])],1):_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle ")])],1)]):_vm._e()])]),_c('tr',[_c('th',{attrs:{"width":"15%"}},[_vm._v("Name")]),_c('th',[_vm._v("Employment")]),_c('th',[_vm._v("Affordability")]),_c('th',[_vm._v("Landlord")]),_c('th',[_vm._v("Pets")]),_c('th',[_vm._v("Smoker")]),_c('th',[_vm._v("Status")]),_c('th',[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.rejectDialog.rejectGroup(group)}}},[_vm._v(" Reject ")])],1)]),_vm._l((group.applications),function(application){return _c('tr',{key:application.id},[_c('td',{class:application.interested_in_property == 1
                      ? 'light-blue lighten-5'
                      : ' '},[_vm._v(" "+_vm._s(application.full_name)+" "),(application.read == 0)?_c('v-badge',{attrs:{"color":"red white--text","content":"New","inline":""}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(application.employment_status))]),_c('td',[(application.monthly_salary)?_c('div',[(
                        (group.applications[0].property.rent * 30) / 12 <=
                        application.monthly_salary
                      )?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle ")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle ")])],1)]):_c('div',[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-help-circle")])],1)]),_c('td',[(application.current_landlord)?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle ")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle ")])],1)]),_c('td',[(application.pets)?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle ")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle ")])],1)]),_c('td',[(application.smoker)?_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle ")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle ")])],1)]),_c('td',[_c('v-btn',{attrs:{"x-small":"","depressed":"","color":application.status.colour +
                      ' ' +
                      (application.status.colour == 'yellow'
                        ? 'black--text lighten-5'
                        : application.status.colour + '--text lighten-5')}},[_vm._v(" "+_vm._s(application.status.status_desc)+" ")])],1),_c('td',[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                      name: 'module-sbpm-applications-individual',
                      params: { applicationId: application.id },
                    }}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"bottom":"","x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(application.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.$refs.rejectDialog.changeStatusPrompt(application)}}},[_vm._v(" Reject ")])],1)])})],2)])}),1)])],1)],1),_c('ApplicationForm',{ref:"applicationForm"}),_c('RejectDialog',{ref:"rejectDialog",attrs:{"fromProperty":false}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteApplication.dialog),callback:function ($$v) {_vm.$set(_vm.deleteApplication, "dialog", $$v)},expression:"deleteApplication.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Application")]),_c('v-card-text',[_vm._v("Are you sure you want to archive this application form?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteApplication.loading},on:{"click":_vm.saveDelete}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }